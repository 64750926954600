<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Tabs</div>
                    <div class="card-body">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                   href="#basicFirst" role="tab">First</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                   href="#basicSecond" role="tab">Second</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                   href="#basicDisabled" role="tab">Disabled</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div id="basicFirst" aria-labelledby="home-tab" class="tab-pane fade show active"></div>
                            <div id="basicSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                            <div id="basicDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Fill</div>
                    <div class="card-body">
                        <div>
                            <ul class="nav nav-tabs nav-fill" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                       href="#fillFirst" role="tab">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                       href="#fillSecond" role="tab">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                       href="#fillDisabled" role="tab">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div id="fillFirst" aria-labelledby="home-tab" class="tab-pane fade show active" role="tabpanel"></div>
                                <div id="fillSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                                <div id="fillDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <ul class="nav nav-pills nav-fill" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                       href="#pillsFillFirst" role="tab">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                       href="#pillsFillSecond" role="tab">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                       href="#pillsFillDisabled" role="tab">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div id="pillsFillFirst" aria-labelledby="home-tab" class="tab-pane fade show active" role="tabpanel"></div>
                                <div id="pillsFillSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                                <div id="piillsFillDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Pills Variant</div>
                    <div class="card-body">
                        <ul class="nav nav-pills" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                   href="#basicPillsFirst" role="tab">First</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                   href="#basicPillsSecond" role="tab">Second</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                   href="#basicPillsDisabled" role="tab">Disabled</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div id="basicPillsFirst" aria-labelledby="home-tab" class="tab-pane fade show active" role="tabpanel"></div>
                            <div id="basicPillsSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                            <div id="basicPillsDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Justified</div>
                    <div class="card-body">
                        <div>
                            <ul class="nav nav-tabs nav-justified" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                       href="#justifiedFirst" role="tab">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                       href="#justifiedSecond" role="tab">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                       href="#justifiedDisabled" role="tab">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div id="justifiedFirst" aria-labelledby="home-tab" class="tab-pane fade show active" role="tabpanel"></div>
                                <div id="justifiedSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                                <div id="justifiedDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <ul class="nav nav-pills nav-justified" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                       href="#pillsJustifiedFirst" role="tab">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                       href="#pillsJustifiedSecond" role="tab">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a aria-controls="profile" aria-selected="false" class="nav-link disabled"
                                       data-toggle="tab" href="#pillsJustifiedDisabled" role="tab">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div id="pillsJustifiedFirst" aria-labelledby="home-tab" class="tab-pane fade show active" role="tabpanel"></div>
                                <div id="pillsJustifiedSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                                <div id="piillsJustifiedDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Tabs Alignment</div>
                    <div class="card-body">
                        <ul class="nav nav-tabs justify-content-center" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                   href="#basicCenterFirst" role="tab">First</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                   href="#basicCenterSecond" role="tab">Second</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                   href="#basicCenterDisabled" role="tab">Disabled</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div id="basicCenterFirst" aria-labelledby="home-tab" class="tab-pane fade show active" role="tabpanel"></div>
                            <div id="basicCenterSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                            <div id="basicCenterDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                        </div>

                        <ul class="nav nav-tabs justify-content-end" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                   href="#basicEndFirst" role="tab">First</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                   href="#basicEndSecond" role="tab">Second</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                   href="#basicEndDisabled" role="tab">Disabled</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div id="basicEndFirst" aria-labelledby="home-tab" class="tab-pane fade show active" role="tabpanel"></div>
                            <div id="basicEndSecond" aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel"></div>
                            <div id="basicEndDisabled" aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Vertical Tabs</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-3">
                                <div aria-orientation="vertical" class="nav flex-column nav-pills" role="tablist">
                                    <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                       href="#vLeftFirst" role="tab">First</a>
                                    <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                       href="#vLeftSecond" role="tab">Second</a>
                                    <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                       href="#vLeftDisabled" role="tab">Disabled</a>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="tab-content">
                                    <div id="vLeftFirst" aria-labelledby="home-tab" class="tab-pane fade show active"
                                         role="tabpanel">Lorem ipsum dolor sit amet, consectetur adipisicing
                                        elit. Asperiores dolor illo ipsam obcaecati provident quos. In ipsum iste non
                                        ut. Asperiores aut commodi laboriosam minima nisi possimus quia repudiandae
                                        vero.
                                    </div>
                                    <div id="vLeftSecond" aria-labelledby="profile-tab" class="tab-pane fade"
                                         role="tabpanel">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Id iure quasi voluptatem? A adipisci aspernatur, blanditiis
                                        dolor earum eius expedita iusto laborum magnam maiores natus nesciunt officia
                                        similique velit veritatis?
                                    </div>
                                    <div id="vLeftDisabled" aria-labelledby="contact-tab" class="tab-pane fade"
                                         role="tabpanel">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Autem hic inventore ipsum iure magni perferendis repudiandae
                                        soluta! Architecto, earum est fuga in ipsam iure, iusto, laborum nemo quam ullam
                                        voluptatibus.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row mt-4">
                            <div class="col-9">
                                <div class="tab-content">
                                    <div id="vRightFirst" aria-labelledby="home-tab" class="tab-pane fade show active"
                                         role="tabpanel">Lorem ipsum dolor sit amet, consectetur adipisicing
                                        elit. Aspernatur, assumenda consequuntur cumque dicta, distinctio eaque earum,
                                        eligendi esse id impedit in iure minus natus perspiciatis similique. Magni
                                        nesciunt nihil sapiente.
                                    </div>
                                    <div id="vRightSecond" aria-labelledby="profile-tab" class="tab-pane fade"
                                         role="tabpanel">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Ad cumque distinctio esse optio possimus quasi quo? Alias
                                        architecto deserunt ipsum, itaque magni molestias placeat possimus qui quos
                                        reprehenderit, similique, vero.
                                    </div>
                                    <div id="vRightDisabled" aria-labelledby="contact-tab" class="tab-pane fade"
                                         role="tabpanel">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Aperiam, architecto aspernatur autem consequatur corporis
                                        dolor est facilis hic, labore maiores, officia omnis quidem repellendus totam
                                        veritatis. Ex facilis repellat voluptatum?
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div aria-orientation="vertical" class="nav flex-column nav-pills" role="tablist">
                                    <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab"
                                       href="#vRightFirst" role="tab">First</a>
                                    <a aria-controls="profile" aria-selected="false" class="nav-link" data-toggle="tab"
                                       href="#vRightSecond" role="tab">Second</a>
                                    <a aria-controls="profile" aria-selected="false" class="nav-link disabled" data-toggle="tab"
                                       href="#vRightDisabled" role="tab">Disabled</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs'
}
</script>
